<template>
    <div :class="$style['battles-create']">
        <BattlesCreateFilters />

        <div :class="$style['create-select']">
            <div :class="$style['select-wrap']">
                <div
                    :class="$style['select-list']"
                    :style="{ minWidth: (battlesGetBoxes.length + 1) * (180 + 7) + 'px' }"
                    @dragover.prevent
                >
                    <button
                        v-on:click="modalsSetShow('BattlesSelect')"
                        :class="$style['button-add']"
                    >
                        <div :class="$style['button-inner']">
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.5858 4.42169H12V7.6506H7.5858V12H4.4142V7.6506H0V4.42169H4.4142V0H7.5858V4.42169Z"
                                />
                            </svg>
                        </div>
                    </button>

                    <div
                        v-for="(box, index) in battlesGetBoxes"
                        :class="[
                            $style['select-item'],
                            { [$style['active']]: draggedIndex === box.pos }
                        ]"
                        v-bind:key="index"
                        :draggable="true"
                        @dragstart="onDragStart($event, box.pos)"
                        @dragenter.prevent="onDragEnter($event, box.pos)"
                        @dragover.prevent
                        @dragend="onDragEnd"
                        :style="{
                            transform: `translateX(${(box.pos + 1) * (180 + 7)}px)`,
                            transition: 'transform 0.3s ease'
                        }"
                    >
                        <svg
                            stroke="currentColor"
                            fill="none"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                            ></path>
                        </svg>
                        <BattlesBoxElement v-bind:box="box" />
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <BattlesCreateFooter />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconCursedGradient from '@/components/icons/IconCursedGradient';
    import IconTerminalGradient from '@/components/icons/IconTerminalGradient';
    import BattlesCreateFilters from '@/components/battles/BattlesCreateFilters';
    import BattlesCreateFooter from '@/components/battles/BattlesCreateFooter';
    import BattlesBoxElement from '@/components/battles/BattlesBoxElement';

    export default {
        name: 'BattlesCreate',
        components: {
            IconCursedGradient,
            IconTerminalGradient,
            BattlesCreateFilters,
            BattlesCreateFooter,
            BattlesBoxElement
        },
        data() {
            return {
                draggedIndex: null,
                draggedItem: null,
                dragEnterCooldown: false
            };
        },
        methods: {
            ...mapActions(['modalsSetShow', 'battlesResetFilter', 'battlesMoveSelected']),
            onDragStart(event, index) {
                this.draggedIndex = index;
                this.draggedItem = this.battlesSelected[index];
                event.dataTransfer.effectAllowed = 'move';
            },
            onDragEnter(event, dropIndex) {
                if (this.dragEnterCooldown === true) return;

                if (
                    this.draggedIndex !== null &&
                    this.draggedIndex !== dropIndex &&
                    typeof dropIndex === 'number'
                ) {
                    const data = {
                        box: this.draggedItem,
                        from: this.draggedIndex,
                        to: dropIndex
                    };
                    this.dragEnterCooldown = true;
                    this.battlesMoveSelected(data);
                    this.draggedIndex = dropIndex;
                    this.draggedItem = { ...this.draggedItem, pos: dropIndex };
                    setTimeout(() => (this.dragEnterCooldown = false), 300);
                }
            },
            onDragEnd() {
                this.draggedIndex = null;
                this.draggedItem = null;
            }
        },
        computed: {
            ...mapGetters(['battlesSelected']),
            battlesGetBoxes() {
                return this.battlesSelected
                    .map((element, i) => ({ ...element, pos: i }))
                    .sort((a, b) => a.amount - b.amount);
            }
        },
        beforeRouteLeave(to, from, next) {
            this.battlesResetFilter();
            next();
        }
    };
</script>

<style module>
    .battles-create {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
    }

    .battles-create hr {
        border: unset;
        height: 1px;
        background: linear-gradient(90deg, #6170f129, #1c47b629);
    }

    .battles-create .create-filter {
    }

    .battles-create .create-select {
        width: 100%;
        display: flex;
        padding: 14px;
        background: linear-gradient(
                211.46deg,
                rgba(5, 29, 48, 0.35) 2.26%,
                rgba(31, 99, 153, 0) 100.96%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        backdrop-filter: blur(2.5px);
        position: relative;
        font-size: 14px;
        gap: 7px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select::before {
        content: '';
        position: absolute;
        background: #0a273f;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-size: 14px;
        clip-path: var(--octagonal-before);
    }

    .battles-create .select-list {
        position: relative;
    }

    .battles-create .select-wrap {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .battles-create .select-list button.button-add {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 18 / 26;
        width: 180px;
        position: relative;
        background: linear-gradient(10deg, #051421, #0b1e2f, #071929 110%);
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select button.button-add::before,
    .battles-create .create-select button.button-add .button-inner::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0a273f;
        font-size: 7px;
        clip-path: var(--octagonal-before);
    }

    .battles-create .create-select button.button-add .button-inner::before {
        font-size: 5px;
    }

    .battles-create .create-select button.button-add .button-inner {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        background: #10273e;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .battles-create .create-select button.button-add .button-inner svg {
        fill: #406992;
        height: 10px;
        width: 10px;
        transition: fill 0.3s ease;
    }

    .battles-create .create-select button.button-add:hover .button-inner svg {
        fill: #ffffff;
    }

    .battles-create .select-item {
        transition: transform 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 180px;
    }

    .battles-create .select-item.active {
        opacity: 0.8;
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    .battles-create .select-item > svg {
        position: absolute;
        display: none;
        z-index: 1;
        font-size: 20px;
        color: #537088;
        right: 10px;
        top: 14px;
    }

    .battles-create .select-item:hover > svg {
        display: block;
    }

    @media only screen and (max-width: 1100px) {
        .battles-create .create-select {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 950px) {
        .battles-create .create-select {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 750px) {
        .battles-create .create-select {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 550px) {
        .battles-create .create-select {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
</style>
