<template>
    <div
        :class="[
            $style['battles-box-element'],
            { [$style['element-selected']]: battlesSelectedCount >= 1 }
        ]"
        class="octagonal before after"
    >
        <div :class="$style['element-name']">
            {{ box.name }}
        </div>
        <div :class="$style['element-price']">
            <img
                v-if="userBalanceData.type === 'cryptoCoin'"
                src="@/assets/img/icons/cryptoCoin.svg"
                alt="icon"
            />
            <img
                v-else-if="userBalanceData.type === 'robuxCoin'"
                src="@/assets/img/icons/robuxCoin.svg"
                alt="icon"
            />
            <div :class="$style['price-value']">
                <span>{{ battlesFormatValue(box.amount).split('.')[0] }}</span
                >.{{ battlesFormatValue(box.amount).split('.')[1] }}
            </div>
        </div>
        <div :class="$style['element-image']" v-on:click="battlesBoxButton">
            <img v-bind:src="unboxImagePath + '/public/img/' + box.slug + '.png'" />
        </div>
        <div v-if="battlesSelectedCount === 0" :class="$style['element-select']">
            <div :class="$style['button-placeholder']" class="octagonal before after hoverable">
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.0572 2.94779H8V5.1004H5.0572V8H2.9428V5.1004H0V2.94779H2.9428V0H5.0572V2.94779Z"
                        fill="#406992"
                    />
                </svg>
            </div>
            <button
                v-on:click="battlesAddButton()"
                :class="$style['button-add']"
                class="octagonal before after hoverable"
            >
                <span>Add Case</span>
            </button>
        </div>
        <div v-else :class="$style['element-count']">
            <div
                v-on:click="battlesDecreaseButton()"
                :class="$style['button-decrease']"
                class="octagonal before after hoverable"
            >
                <svg
                    width="7"
                    height="2"
                    viewBox="0 0 7 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 2V0H7V2H0Z" />
                </svg>
            </div>
            {{ battlesSelectedCount }}
            <div
                v-on:click="battlesIncreaseButton()"
                :class="$style['button-increase']"
                class="octagonal before after hoverable"
            >
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.0572 2.94779H8V5.1004H5.0572V8H2.9428V5.1004H0V2.94779H2.9428V0H5.0572V2.94779Z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BattlesBoxElement',
        props: ['box', 'returnModal'],
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL
            };
        },
        methods: {
            ...mapActions([
                'battlesAddSelected',
                'battlesRemoveSelected',
                'battlesEmptySelected',
                'modalsSetShow',
                'modalsSetData'
            ]),
            battlesBoxButton() {
                const data = { box: this.box };
                if (this.returnModal) data.returnModal = this.returnModal;
                this.modalsSetData(data);
                this.modalsSetShow('Box');
            },
            battlesFormatValue(value) {
                if (value.toString().length > 9)
                    return (
                        parseFloat(Math.floor(value / 10) / 100000000)
                            .toFixed(3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm'
                    );
                else
                    return parseFloat(Math.floor(value / 10) / 100)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesAddButton() {
                this.battlesAddSelected(this.box);
            },
            battlesIncreaseButton() {
                this.battlesAddSelected(this.box);
            },
            battlesDecreaseButton() {
                this.battlesRemoveSelected(this.box);
            }
        },
        computed: {
            ...mapGetters(['battlesSelected', 'userBalanceData']),
            battlesSelectedCount() {
                return (
                    this.battlesSelected.find((element) => element._id === this.box._id)?.count || 0
                );
            }
        }
    };
</script>

<style module>
    .battles-box-element {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px 20px 30px;
        gap: 10px;
        aspect-ratio: 18 / 26;
        width: 100%;
    }

    .battles-box-element::before {
        background: #0a273f;
        font-size: 7px;
    }

    .battles-box-element::after {
        background: linear-gradient(10deg, #051421, #0b1e2f, #071929 110%);
        font-size: 7px;
    }

    .battles-box-element .element-name {
        width: 100%;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        text-align: center;
        color: white;
    }

    .battles-box-element .element-price {
        position: relative;
        background: #112c43cc;
        min-width: 85%;
        width: fit-content;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        padding: 3px 4px 3px 4px;
        gap: 7px;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .battles-box-element .price-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .battles-box-element .price-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-box-element .element-image {
        flex: 1;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        cursor: zoom-in;
    }

    .battles-box-element .element-image img {
        position: absolute;
        left: 0;
        top: 2%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.1s ease;
        transform: scale(0.9) rotate(0deg);
    }

    .battles-box-element:hover .element-image img {
        transform: scale(1) rotate(-5deg);
    }

    .battles-box-element .element-image:hover img {
        transform: scale(0.95) rotate(1deg);
    }

    .battles-box-element .element-count {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    .battles-box-element .element-count .button-decrease,
    .battles-box-element .element-count .button-increase {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        filter: drop-shadow(0 2px 0 #00000039);
    }

    .battles-box-element .element-count .button-decrease::before,
    .battles-box-element .element-count .button-increase::before,
    .battles-box-element .element-count .button-decrease::after,
    .battles-box-element .element-count .button-increase::after {
        background: #132c41bb;
        font-size: 3px;
    }

    .battles-box-element .element-count .button-decrease svg,
    .battles-box-element .element-count .button-increase svg {
        fill: #537088;
        transition: fill 0.3s ease;
    }

    .battles-box-element .element-count .button-decrease:hover svg,
    .battles-box-element .element-count .button-increase:hover svg {
        fill: #ffffff;
    }

    .battles-box-element .element-select {
        height: 30px;
        width: 80%;
        position: relative;
    }

    .battles-box-element .button-add,
    .battles-box-element .button-placeholder {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .battles-box-element .button-placeholder::before,
    .battles-box-element .button-placeholder::after {
        background: #10293e;
        font-size: 3px;
    }

    .battles-box-element .button-add {
        display: none;
    }

    .battles-box-element:hover .button-add {
        display: block;
        padding-bottom: 1px;
    }

    .battles-box-element .button-add span {
        color: white;
        font-size: 14px;
        font-weight: 600;
    }

    .battles-box-element .button-add {
        filter: drop-shadow(0 2px 0 #136762), drop-shadow(0 2px 10px #21b89459);
    }

    .battles-box-element .button-add::before,
    .battles-box-element .button-add::after {
        background: #21b894;
        font-size: 3px;
    }

    @media screen and (max-width: 475px) {
        .battles-box-element {
            padding: 15px;
        }
    }
</style>
