<template>
    <div :class="$style['battles-header-create']" class="octagonal after">
        <div :class="$style['create-content']" class="octagonal before after">
            <router-link
                to="/battles"
                :class="$style['content-back']"
                class="octagonal before after hoverable"
            >
                <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 9.51762L6 0.482355C6 0.0547938 5.43467 -0.163021 5.09901 0.143533L0.152375 4.66117C-0.05079 4.84671 -0.0507901 5.15326 0.152375 5.33889L5.09901 9.85652C5.43467 10.163 6 9.94518 6 9.51762Z"
                        fill="#4C6F8D"
                    />
                </svg>
                <p>Back to lobby</p>
            </router-link>

            <div :class="$style['content-title']">
                <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.05531 11.2261L8.94249 15.0407L7.38828 16.5662L8.94469 18.0927L7.38938 19.6181L4.66703 16.9481L1.55531 20L0 18.4746L3.11172 15.4215L0.389378 12.7526L1.94469 11.2271L3.5 12.7515L5.05531 11.2261ZM0.600566 0L4.50094 0.00323644L17.4989 12.7526L19.0553 11.2271L20.6106 12.7526L17.8894 15.4226L21 18.4746L19.4447 20L16.333 16.9481L13.6106 19.6181L12.0553 18.0927L13.6106 16.5662L0.603866 3.80927L0.600566 0ZM16.5024 0L20.3994 0.00323644L20.4016 3.80387L15.9436 8.1752L12.0542 4.36162L16.5024 0Z"
                        fill="#21B894"
                    />
                </svg>
                <p>BATTLES CREATION</p>
            </div>

            <div :class="$style['content-cost']">
                <p>Total Cost</p>
                <div :class="$style['cost-amount']">
                    <img
                        v-if="userBalanceData.type === 'cryptoCoin'"
                        src="@/assets/img/icons/cryptoCoin.svg"
                        width="14"
                        alt="icon"
                    />
                    <img
                        v-else-if="userBalanceData.type === 'robuxCoin'"
                        src="@/assets/img/icons/robuxCoin.svg"
                        width="16"
                        alt="icon"
                    />
                    <p>
                        {{ battlesFormatValue(battlesGetCost).split('.')[0]
                        }}<span>.{{ battlesFormatValue(battlesGetCost).split('.')[1] }}</span>
                    </p>
                </div>
            </div>

            <button
                v-on:click="battlesCreateButton()"
                :class="$style['content-create']"
                v-bind:disabled="socketSendLoading !== null"
                class="octagonal before after hoverable"
            >
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.37021 7.85824L5.96166 10.5285L4.92552 11.5963L5.96313 12.6649L4.92625 13.7327L3.11136 11.8636L1.03687 14L0 12.9322L2.07448 10.7951L0.259585 8.9268L1.29646 7.859L2.33333 8.92605L3.37021 7.85824ZM0.400377 0L3.00063 0.00226551L11.6659 8.9268L12.7035 7.859L13.7404 8.9268L11.9263 10.7958L14 12.9322L12.9631 14L10.8886 11.8636L9.07375 13.7327L8.03687 12.6649L9.07375 11.5963L0.402577 2.66649L0.400377 0ZM11.0016 0L13.5996 0.00226551L13.6011 2.66271L10.6291 5.72264L8.03614 3.05313L11.0016 0Z"
                        fill="white"
                    />
                </svg>
                CREATE
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'BattlesHeaderCreate',
        components: {
            ButtonLoading
        },
        methods: {
            ...mapActions(['battlesSendCreateSocket']),
            battlesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            battlesCreateButton() {
                let boxes = [];

                for (let selected of this.battlesSelected) {
                    boxes.push({ _id: selected._id, count: selected.count || 1 });
                }

                const mode = this.battlesFilterMode === '2v2' ? 'team' : this.battlesFilterType;

                const data = {
                    boxes: boxes,
                    playerCount: this.battlesGetCountPlayer,
                    mode: mode,
                    levelMin: this.battlesFilterLevel,
                    funding: this.battlesFilterFunding,
                    private: this.battlesFilterPrivate,
                    affiliateOnly: this.battlesFilterAffiliate,
                    cursed: this.battlesFilterCursed,
                    terminal: this.battlesFilterTerminal,
                    type: this.userBalanceData.type
                };
                this.battlesSendCreateSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'battlesSelected',
                'battlesFilterMode',
                'battlesFilterType',
                'battlesFilterLevel',
                'battlesFilterFunding',
                'battlesFilterPrivate',
                'battlesFilterAffiliate',
                'battlesFilterCursed',
                'battlesFilterTerminal',
                'userBalanceData'
            ]),
            battlesGetCount() {
                return this.battlesSelected.length;
            },
            battlesGetCountPlayer() {
                let count = 2;

                if (this.battlesFilterMode === '2v2' || this.battlesFilterMode === '1v1v1v1') {
                    count = 4;
                } else if (this.battlesFilterMode === '1v1v1') {
                    count = 3;
                }

                return count;
            },
            battlesGetCost() {
                let cost = 0;

                for (let box of this.battlesSelected) {
                    cost = Math.floor(cost + box.amount * (box.count || 1));
                }

                cost = Math.floor(
                    cost +
                        (cost *
                            this.battlesGetCountPlayer *
                            Math.floor(this.battlesFilterFunding)) /
                            100
                );

                return cost;
            }
        }
    };
</script>

<style module>
    .battles-header-create {
        padding: 4px;
        margin-bottom: 30px;
    }

    .battles-header-create::after {
        clip-path: var(--octagonal-main) !important;
        font-size: 14px;
        background: #06233b;
    }

    .battles-header-create .create-content {
        padding: 14px 22px;
        align-items: center;
        display: flex;
        height: 69px;
        gap: 14px;
    }

    .battles-header-create .create-content::before {
        font-size: 12px;
        background: #0d3051;
    }

    .battles-header-create .create-content::after {
        font-size: 12px;
        background: linear-gradient(90deg, #0a1c2e, #0c2132);
    }

    .battles-header-create .content-back {
        height: 36px;
        min-width: 36px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        font-size: 15px;
        color: #537088;
        font-weight: 600;
        filter: drop-shadow(0 2px 0 #0a2134);
    }

    .battles-header-create .content-back::before,
    .battles-header-create .content-back::after {
        font-size: 3px;
        background: #132c41;
    }

    .battles-header-create .content-title {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 18px;
        color: white;
        font-weight: 600;
        margin-right: auto;
    }

    .battles-header-create .content-cost {
        align-items: center;
        color: #537088;
        font-weight: 600;
        font-size: 15px;
        display: flex;
    }

    .battles-header-create .cost-amount {
        background: linear-gradient(90deg, transparent, #0e4747, transparent);
        min-width: 120px;
        padding: 3px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        color: white;
    }

    .battles-header-create .cost-amount span {
        font-size: 11px;
        opacity: 0.6;
    }

    .battles-header-create .content-create {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: white;
        width: 113px;
        height: 39px;
        filter: drop-shadow(0 2px 0 #136762), drop-shadow(0 2px 10px #21b89459);
        gap: 7px;
    }

    .battles-header-create .content-create::before,
    .battles-header-create .content-create::after {
        font-size: 3px;
        background: #21b894;
    }

    @media screen and (max-width: 800px) {
        .battles-header-create .content-back p {
            display: none;
        }
    }

    @media screen and (max-width: 700px) {
        .battles-header-create .content-cost {
            flex-direction: column;
        }

        .battles-header-create .content-cost > p {
            margin-bottom: -5px;
            margin-top: -2px;
            position: relative;
            z-index: 1;
        }
    }

    @media screen and (max-width: 600px) {
        .battles-header-create .content-title p {
            display: none;
        }
    }
</style>
