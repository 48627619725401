<template>
    <div :class="$style['battles-overview']">
        <div
            v-if="myBattles.length || allBattles.length"
            :class="$style['content-list']"
            key="data"
        >
            <p v-if="myBattles.length" :class="$style['list-title']">My Battles</p>
            <div
                v-for="(game, index) in myBattles"
                v-bind:key="game._id || index"
                :class="[
                    $style['list-item'],
                    { [$style['pop']]: index === 0 && mounting === 'my' }
                ]"
            >
                <BattlesGameElement v-bind:game="game" />
            </div>
            <p v-if="allBattles.length" :class="$style['list-title']">All Battles</p>
            <div
                v-for="(game, index) in allBattles"
                v-bind:key="game._id || index"
                :class="[
                    $style['list-item'],
                    { [$style['pop']]: index === 0 && mounting === 'all' }
                ]"
            >
                <BattlesGameElement v-bind:game="game" />
            </div>
        </div>
        <div v-else :class="$style['content-empty']" key="empty">There are no battles.</div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BattlesGameElement from '@/components/battles/BattlesGameElement';

    export default {
        name: 'BattlesOverview',
        components: {
            BattlesGameElement
        },
        data() {
            return {
                mounting: null,
                allBattles: [],
                myBattles: []
            };
        },
        computed: {
            ...mapGetters([
                'authUser',
                'socketBattles',
                'battlesGames',
                'battlesHistory',
                'battlesFilterSortGames'
            ]),
            battlesGetGames() {
                let games = this.battlesGames;

                if (this.battlesFilterSortGames === 'price') {
                    games.sort(function (a, b) {
                        return b.amount - a.amount;
                    });
                } else {
                    games.sort(function (a, b) {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                    });
                }

                games = [...games, ...this.battlesHistory];

                return games;
            }
        },
        methods: {
            battlesGetBets(game) {
                let bets = [];

                for (let bet = 0; bet < game.playerCount; bet++) {
                    const index = game.bets.findIndex((element) => element.slot === bet);

                    bets.push(index !== -1 ? game.bets[index] : null);
                }

                return bets;
            },
            battlesGetAllGames() {
                if (this.socketBattles.connected === false) {
                    return [{}, {}, {}, {}, {}];
                } else {
                    return this.battlesGetGames.filter(
                        (game) =>
                            !game.bets.some((bet) =>
                                this.authUser?.user?.username
                                    ? bet.user.username === this.authUser?.user?.username
                                    : false
                            )
                    );
                }
            },
            battlesGetMyGames() {
                return this.battlesGetGames.filter((game) =>
                    game.bets.some(
                        (bet) =>
                            this.authUser?.user?.username &&
                            bet.user.username === this.authUser?.user?.username
                    )
                );
            }
        },
        created() {
            this.allBattles = this.battlesGetAllGames();
            this.myBattles = this.battlesGetMyGames();
        },
        watch: {
            battlesGetGames: {
                handler() {
                    const allGames = this.battlesGetAllGames();
                    const myGames = this.battlesGetMyGames();
                    if (this.battlesFilterSortGames !== 'price') {
                        if (allGames.length > this.allBattles.length) {
                            this.mounting = 'all';
                        } else if (myGames.length > this.myBattles.length) {
                            this.mounting = 'my';
                        }
                        setTimeout(() => (this.mounting = null), 300);
                    }
                    this.allBattles = allGames;
                    this.myBattles = myGames;
                }
            }
        }
    };
</script>

<style module>
    .battles-overview .list-title {
        font-weight: 600;
        font-size: 14px;
        color: #537088;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .battles-overview .list-title:first-child {
        margin-top: 0px;
    }

    .battles-overview .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .battles-overview .list-item {
        display: flex;
        align-items: flex-end;
    }

    .battles-overview .content-list .list-item.pop {
        animation: battles-overview-item 0.3s ease;
        overflow: hidden;
    }

    @keyframes battles-overview-item {
        0% {
            max-height: 0px;
        }
        100% {
            max-height: 150px;
        }
    }
</style>
