<template>
    <div :class="$style.modals">
        <transition name="fade">
            <div :class="$style['modals-overlay']" v-show="modalsShow !== null"></div>
        </transition>
        <transition name="slide-fade" mode="out-in">
            <article
                :class="$style['modals-holder']"
                v-if="modalsShow !== null"
                v-on:click="modalsCloseButton"
            >
                <div :class="$style['holder-body']">
                    <div :class="$style['body-modal']">
                        <button
                            v-if="
                                ['DuelsGame', 'BattlesSelect', 'Box'].includes(modalsShow) !== true
                            "
                            v-on:click="modalsSetShow(null)"
                            class="octagonal before after hoverable x"
                            :class="$style['button-close']"
                        >
                            <div :class="$style['button-inner']">
                                <IconClose />
                            </div>
                        </button>

                        <component v-bind:is="'Modal' + modalsShow" />
                    </div>
                </div>
            </article>
        </transition>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import ModalCaptcha from '@/components/modals/ModalCaptcha';
    import ModalLogin from '@/components/modals/ModalLogin';
    import ModalLink from '@/components/modals/ModalLink';
    import ModalLinkRoblox from '@/components/modals/ModalLinkRoblox';
    import ModalReset from '@/components/modals/ModalReset';
    import ModalClaim from '@/components/modals/ModalClaim';
    import ModalCurrencies from '@/components/modals/ModalCurrencies';
    import ModalChatRules from '@/components/modals/ModalChatRules';
    import ModalChatUser from '@/components/modals/ModalChatUser';
    import ModalRemove from '@/components/modals/ModalRemove';
    import ModalMute from '@/components/modals/ModalMute';
    import ModalBan from '@/components/modals/ModalBan';
    import ModalCashier from '@/components/modals/ModalCashier';
    import ModalGift from '@/components/modals/ModalGift';
    import ModalProceed from '@/components/modals/ModalProceed';
    import ModalTwoStep from '@/components/modals/ModalTwoStep';
    import ModalVault from '@/components/modals/ModalVault';
    import ModalTip from '@/components/modals/ModalTip';
    import ModalFair from '@/components/modals/ModalFair';
    import ModalFairSeed from '@/components/modals/ModalFairSeed';
    import ModalFairGame from '@/components/modals/ModalFairGame';
    import ModalWhatsNew from '@/components/modals/ModalWhatsNew';
    import ModalFaq from '@/components/modals/ModalFaq';
    import ModalTerms from '@/components/modals/ModalTerms';
    import ModalPrivacy from '@/components/modals/ModalPrivacy';
    import ModalBlackjackRules from '@/components/modals/ModalBlackjackRules';
    import ModalDuelsGame from '@/components/modals/ModalDuelsGame';
    import ModalBattlesModes from '@/components/modals/ModalBattlesModes';
    import ModalBattlesSelect from '@/components/modals/ModalBattlesSelect';
    import ModalBox from '@/components/modals/ModalBox';
    import ModalAdminConfirm from '@/components/modals/ModalAdminConfirm';
    import ModalAdminUser from '@/components/modals/ModalAdminUser';
    import ModalAdminAffiliate from '@/components/modals/ModalAdminAffiliate';
    import ModalAdminCrypto from '@/components/modals/ModalAdminCrypto';

    export default {
        components: {
            IconClose,
            ModalCaptcha,
            ModalLogin,
            ModalLink,
            ModalLinkRoblox,
            ModalReset,
            ModalClaim,
            ModalCurrencies,
            ModalChatRules,
            ModalChatUser,
            ModalRemove,
            ModalMute,
            ModalBan,
            ModalVault,
            ModalCashier,
            ModalGift,
            ModalProceed,
            ModalTwoStep,
            ModalTip,
            ModalFair,
            ModalFairSeed,
            ModalFairGame,
            ModalWhatsNew,
            ModalFaq,
            ModalTerms,
            ModalPrivacy,
            ModalBlackjackRules,
            ModalDuelsGame,
            ModalBattlesModes,
            ModalBattlesSelect,
            ModalBox,
            ModalAdminConfirm,
            ModalAdminUser,
            ModalAdminAffiliate,
            ModalAdminCrypto
        },
        methods: {
            ...mapActions(['modalsSetShow', 'modalsSetData']),
            modalsCloseButton(e) {
                if (
                    e.target.className === this.$style['modals-holder'] ||
                    e.target.className === this.$style['holder-body']
                ) {
                    if (this.modalsData?.returnModal) {
                        this.modalsSetShow(this.modalsData.returnModal);
                        this.modalsSetData(null);
                    } else {
                        this.modalsSetShow(null);
                    }
                }
            },
            handleEscapeKey(event) {
                if (event.key === 'Escape') {
                    if (this.modalsData?.returnModal) {
                        this.modalsSetShow(this.modalsData.returnModal);
                        this.modalsSetData(null);
                    } else {
                        this.modalsSetShow(null);
                    }
                }
            }
        },
        computed: {
            ...mapGetters(['modalsShow', 'modalsData'])
        },
        created() {
            document.addEventListener('keydown', this.handleEscapeKey);
        }
    };
</script>

<style scoped>
    div.fade-enter-active,
    div.fade-leave-active {
        transition: opacity 0.3s ease;
    }

    div.fade-enter,
    div.fade-leave-to {
        opacity: 0;
    }

    article.slide-fade-enter-active,
    article.slide-fade-leave-active {
        transition: all 0.3s ease;
    }

    article.slide-fade-enter,
    article.slide-fade-leave-to {
        transform: translate(0, 50px);
        opacity: 0;
    }
</style>

<style module>
    .modals {
        position: relative;
    }

    .body-modal > *:not(.button-close) {
        max-width: calc(100vw - 20px);
        position: relative;
        font-size: 22px;
        clip-path: var(--octagonal-main);
        background: linear-gradient(45deg, #0d243a, #0d243a);
    }

    .body-modal > *:not(.button-close)::before,
    .body-modal > *:not(.button-close)::after {
        transform: translate(-50%, -50%);
        content: '';
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        font-size: 18.5px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
    }

    .body-modal > *:not(.button-close)::before {
        clip-path: var(--octagonal-before);
        background: #1b3053;
    }

    .body-modal > *:not(.button-close)::after {
        clip-path: var(--octagonal-after);
        background: linear-gradient(45deg, #0a2034, #0b2136, #0a1f33);
    }

    .modals .modals-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(2, 20, 35, 0.5);
        backdrop-filter: blur(2px);
        user-select: none;
        z-index: 101;
    }

    .modals .modals-holder {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-behavior: smooth;
        z-index: 102;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .modals .modals-holder::after {
        content: '';
    }

    .modals .modals-holder::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .modals .modals-holder::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .modals .holder-body {
        overflow: auto;
        padding: 14px 0;
        max-height: 100vh;
    }

    .modals .body-modal {
        position: relative;
        border-radius: 15px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    .modals .body-modal button.button-close {
        width: 35px;
        height: 35px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 15px;
        right: 20px;
        color: #406992;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
        z-index: 1;
    }

    .modals .body-modal.modal-square button.button-close {
        top: 15px;
        right: 15px;
    }

    .modals .body-modal button.button-close::after {
        background: #0d2d47;
        font-size: 5px;
    }

    .modals .body-modal button.button-close::before {
        background: #1e4b7e33;
        font-size: 5px;
    }

    .modals .body-modal.modal-square button.button-close .button-inner {
        background: #0c3351;
    }

    .modals .body-modal button.button-close .button-inner svg {
        fill: #406992;
        transition: fill 0.3s ease;
    }
</style>
