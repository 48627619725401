var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"battlesGameBoxes",class:[
        _vm.$style['battles-game-boxes'],
        { [_vm.$style['boxes-rolling']]: _vm.game !== null && _vm.game.state === 'rolling' }
    ]},[_c('hr'),_c('hr'),(_vm.battlesGetMore)?_c('div',{class:_vm.$style['boxes-more']},[_vm._v("+"+_vm._s(_vm.battlesGetMore)+" more")]):_vm._e(),_c('div',{class:_vm.$style['boxes-split']},[_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr')]),_c('transition-group',{class:_vm.$style['boxes-list'],attrs:{"name":"list","tag":"div"}},_vm._l((_vm.battlesGetBoxes.slice(
                _vm.game.state !== 'completed' ? this.battlesGetRound - 1 : 0,
                this.battlesGetRound + 11
            )),function(box,index){return _c('button',{key:box.pos,staticClass:"hoverable",class:[
                _vm.$style['button-box'],
                {
                    [_vm.$style['button-active']]:
                        ['created', 'countdown', 'pending'].includes(_vm.game.state) ||
                        (box.pos === _vm.battlesGetRound - 1 && _vm.game.state !== 'completed')
                }
            ],on:{"click":function($event){return _vm.battlesBoxButton(box.box)}}},[_c('img',{attrs:{"src":_vm.unboxImagePath + '/public/img/' + box.box.slug + '.png'}})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }