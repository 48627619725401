<template>
    <div :class="$style['modal-battles-select']">
        <button v-on:click="modalsSetShow(null)" :class="$style['button-close']">
            <div :class="$style['button-inner']">
                <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.05577 0.433735L4.63678 2.58434L6.21778 0.433735C6.43545 0.144578 6.65313 0 6.8708 0H8.67521C8.82414 0 8.91579 0.0481929 8.95016 0.144579C8.98453 0.228916 8.95589 0.331325 8.86424 0.451807L5.87408 4.44578L8.9158 8.54819C8.99599 8.65663 9.0189 8.75904 8.98453 8.85542C8.95016 8.95181 8.86424 9 8.72676 9H6.68177C6.45264 9 6.23496 8.85542 6.02875 8.56626L4.41337 6.37952L2.798 8.56626C2.59178 8.85542 2.37984 9 2.16216 9H0.289017C0.151539 9 0.0598865 8.95181 0.0140603 8.85542C-0.0203093 8.75904 0.00833203 8.65663 0.0999844 8.54819L3.15888 4.44578L0.237463 0.469879C0.145811 0.349398 0.117169 0.240964 0.151539 0.144579C0.197365 0.0481929 0.294746 0 0.443681 0H2.40275C2.62042 0 2.8381 0.144578 3.05577 0.433735Z"
                        fill="#406992"
                    />
                </svg>
            </div>
        </button>
        <div :class="$style['select-header']">
            <div :class="$style['header-title']">
                <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.05531 11.2261L8.94249 15.0407L7.38828 16.5662L8.94469 18.0927L7.38938 19.6181L4.66703 16.9481L1.55531 20L0 18.4746L3.11172 15.4215L0.389378 12.7526L1.94469 11.2271L3.5 12.7515L5.05531 11.2261ZM0.600566 0L4.50094 0.00323644L17.4989 12.7526L19.0553 11.2271L20.6106 12.7526L17.8894 15.4226L21 18.4746L19.4447 20L16.333 16.9481L13.6106 19.6181L12.0553 18.0927L13.6106 16.5662L0.603866 3.80927L0.600566 0ZM16.5024 0L20.3994 0.00323644L20.4016 3.80387L15.9436 8.1752L12.0542 4.36162L16.5024 0Z"
                        fill="white"
                    />
                    <path
                        d="M5.05531 11.2261L8.94249 15.0407L7.38828 16.5662L8.94469 18.0927L7.38938 19.6181L4.66703 16.9481L1.55531 20L0 18.4746L3.11172 15.4215L0.389378 12.7526L1.94469 11.2271L3.5 12.7515L5.05531 11.2261ZM0.600566 0L4.50094 0.00323644L17.4989 12.7526L19.0553 11.2271L20.6106 12.7526L17.8894 15.4226L21 18.4746L19.4447 20L16.333 16.9481L13.6106 19.6181L12.0553 18.0927L13.6106 16.5662L0.603866 3.80927L0.600566 0ZM16.5024 0L20.3994 0.00323644L20.4016 3.80387L15.9436 8.1752L12.0542 4.36162L16.5024 0Z"
                        fill="url(#paint0_linear_3319_16458)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_3319_16458"
                            x1="60.7566"
                            y1="-0.804659"
                            x2="8.70414"
                            y2="31.7497"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                    </defs>
                </svg>
                BATTLES CREATION
            </div>
            <div :class="$style['header-filters']">
                <BattlesFilterSearch />
                <!-- <BattlesFilterPrice /> -->
                <BattlesFilterSortCases />
            </div>
        </div>
        <div :class="$style['select-content']">
            <transition name="fade" mode="out-in">
                <div
                    v-if="socketBattles.connected === false"
                    :class="$style['content-loading']"
                    key="loading"
                >
                    <div :class="$style['loading-placeholder']"></div>
                    <div :class="$style['loading-placeholder']"></div>
                    <div :class="$style['loading-placeholder']"></div>
                </div>
                <div
                    v-else-if="battlesGetBoxes.length > 0"
                    :class="$style['content-list']"
                    key="data"
                >
                    <BattlesBoxElement
                        v-for="box of battlesGetBoxes"
                        v-bind:key="box._id"
                        v-bind:box="box"
                        v-bind:returnModal="'BattlesSelect'"
                    />
                </div>
                <div v-else :class="$style['content-empty']" key="empty">There are no boxes.</div>
            </transition>
        </div>
        <div :class="$style['select-footer']">
            <div :class="$style['footer-cost']">
                <p>Total Cost</p>
                <div :class="$style['cost-box']">
                    <img
                        v-if="userBalanceData.type === 'cryptoCoin'"
                        src="@/assets/img/icons/cryptoCoin.svg"
                        alt="icon"
                    />
                    <img
                        v-else-if="userBalanceData.type === 'robuxCoin'"
                        src="@/assets/img/icons/robuxCoin.svg"
                        alt="icon"
                    />
                    <div :class="$style['cost-value']">
                        <span>{{ battlesFormatValue(battlesGetCost).split('.')[0] }}</span
                        >.{{ battlesFormatValue(battlesGetCost).split('.')[1] }}
                    </div>
                </div>
            </div>
            <button
                v-on:click="modalsSetShow(null)"
                :class="$style['button-create']"
                class="octagonal before after hoverable"
            >
                <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.47861 7.85824L6.15342 10.5285L5.08395 11.5963L6.15493 12.6649L5.08471 13.7327L3.21144 11.8636L1.07023 14L0 12.9322L2.14121 10.7951L0.267935 8.9268L1.33816 7.859L2.40839 8.92605L3.47861 7.85824ZM0.413256 0L3.09715 0.00226551L12.0412 8.9268L13.1122 7.859L14.1824 8.9268L12.3099 10.7958L14.4503 12.9322L13.3801 14L11.2389 11.8636L9.36561 13.7327L8.29539 12.6649L9.36561 11.5963L0.415526 2.66649L0.413256 0ZM11.3554 0L14.0371 0.00226551L14.0386 2.66271L10.971 5.72264L8.29463 3.05313L11.3554 0Z"
                    />
                </svg>
                <span>CREATE</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import ButtonLoading from '@/components/ButtonLoading';
    import BattlesFilterSearch from '@/components/battles/BattlesFilterSearch';
    import BattlesFilterPrice from '@/components/battles/BattlesFilterPrice';
    import BattlesFilterSortCases from '@/components/battles/BattlesFilterSortCases';
    import BattlesBoxElement from '@/components/battles/BattlesBoxElement';

    export default {
        name: 'ModalBattlesSelect',
        components: {
            IconClose,
            ButtonLoading,
            BattlesFilterSearch,
            BattlesFilterPrice,
            BattlesFilterSortCases,
            BattlesBoxElement
        },
        methods: {
            ...mapActions(['modalsSetShow', 'battlesSendCreateSocket']),
            battlesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            ...mapGetters([
                'socketBattles',
                'socketSendLoading',
                'battlesFilterSortCases',
                'battlesFilterSearch',
                'battlesFilterPrice',
                'battlesBoxes',
                'battlesSelected',
                'battlesFilterMode',
                'battlesFilterType',
                'battlesFilterLevel',
                'battlesFilterFunding',
                'battlesFilterPrivate',
                'battlesFilterAffiliate',
                'battlesFilterCursed',
                'battlesFilterTerminal',
                'userBalanceData'
            ]),
            battlesGetBoxes() {
                let boxes = [];

                for (const box of this.battlesBoxes) {
                    if (
                        box.name
                            .toLowerCase()
                            .includes(this.battlesFilterSearch.toLowerCase().trim()) === true
                    ) {
                        boxes.push(box);
                    }
                }

                if (this.battlesFilterSortCases === 'highest') {
                    boxes.sort(function (a, b) {
                        return b.amount - a.amount;
                    });
                } else {
                    boxes.sort(function (a, b) {
                        return a.amount - b.amount;
                    });
                }

                if (this.battlesFilterPrice == '0 - 5K') {
                    boxes = boxes.filter((element) => element.amount <= 1000 * 5000);
                } else if (this.battlesFilterPrice == '5K - 25K') {
                    boxes = boxes.filter(
                        (element) => element.amount > 1000 * 5000 && element.amount <= 1000 * 25000
                    );
                } else if (this.battlesFilterPrice == '25K - 100K') {
                    boxes = boxes.filter(
                        (element) =>
                            element.amount > 1000 * 25000 && element.amount <= 1000 * 100000
                    );
                } else if (this.battlesFilterPrice == '+100K') {
                    boxes = boxes.filter((element) => element.amount > 1000 * 100000);
                }

                return boxes;
            },
            battlesGetCountPlayer() {
                let count = 2;

                if (this.battlesFilterMode === '2v2' || this.battlesFilterMode === '1v1v1v1') {
                    count = 4;
                } else if (this.battlesFilterMode === '1v1v1') {
                    count = 3;
                }

                return count;
            },
            battlesGetCost() {
                let cost = 0;

                for (let box of this.battlesSelected) {
                    cost = Math.floor(cost + (box.amount * box.count || 1));
                }

                cost = Math.floor(
                    cost +
                        (cost *
                            this.battlesGetCountPlayer *
                            Math.floor(this.battlesFilterFunding)) /
                            100
                );

                return cost;
            }
        }
    };
</script>

<style scoped>
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-enter-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-enter-from {
        opacity: 0;
    }
</style>

<style module>
    .modal-battles-select {
        width: 1029px;
        position: relative;
        padding: 20px 25px;
    }

    .modal-battles-select button.button-close {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 27.5px;
        right: 25px;
        z-index: 1;
    }

    .modal-battles-select button.button-close .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #082c45;
        font-size: 5px;
        clip-path: var(--octagonal-main);
    }

    .modal-battles-select button.button-close .button-inner svg {
        fill: #6e95b6;
        transition: all 0.3s ease;
    }

    .modal-battles-select button.button-close:hover .button-inner svg {
        fill: #ffffff;
    }

    .modal-battles-select .select-header {
        width: calc(100% - 50px);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #405cd422;
    }

    .modal-battles-select .header-title {
        display: flex;
        align-items: center;
        font-size: 18px;
        height: 46px;
        font-weight: 800;
        color: #ffffff;
        margin-right: auto;
    }

    .modal-battles-select .header-title svg {
        margin-right: 10px;
    }

    .modal-battles-select .header-filters {
        justify-content: flex-end;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 7px;
        flex: 1;
    }

    .modal-battles-select .select-content {
        width: 100%;
        height: 550px;
        padding: 14px 0;
        overflow-y: scroll;
        mask-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.25),
            white,
            white calc(100% - 14px),
            transparent
        );
    }

    .modal-battles-select .content-loading,
    .modal-battles-select .content-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 7px;
    }

    .modal-battles-select .content-empty {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .modal-battles-select .select-footer {
        width: calc(100% + 14px + 14px);
        height: 64px;
        margin-left: -13px;
        margin-bottom: -8.5px;
        padding: 4px 15px 1px 15px;
        overflow: hidden;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }

    .modal-battles-select .select-footer::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% + 18px);
        background: #0000001d;
        font-size: 16px;
        clip-path: var(--octagonal-main);
    }

    .modal-battles-select .select-footer::after {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: linear-gradient(
            90deg,
            rgba(97, 112, 241, 0.15) 0%,
            rgba(28, 71, 182, 0.15) 100%
        );
    }

    .modal-battles-select .footer-cost > p {
        margin-top: -2px;
    }
    .modal-battles-select .footer-cost {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        color: #537088;
    }

    .modal-battles-select .cost-box {
        position: relative;
        display: flex;
        gap: 7px;
        justify-content: center;
        align-items: center;
        height: 26px;
        min-width: 118px;
        padding: 0px 14px;
        background: linear-gradient(90deg, transparent, #0e4747, transparent);
    }

    .modal-battles-select .footer-cost img {
        width: 13px;
        height: 18px;
    }

    .modal-battles-select .cost-value {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-battles-select .cost-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
    }

    .modal-battles-select button.button-create {
        height: 35px;
        width: 102px;
        position: relative;
        padding: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 800;
        color: #ffffff;
        filter: drop-shadow(0 2px 0 #136762), drop-shadow(0 2px 10px #21b89459);
    }

    .modal-battles-select button.button-create::before,
    .modal-battles-select button.button-create::after {
        font-size: 3px;
        background: #21b894;
    }

    .modal-battles-select button.button-create span {
        font-size: 14px;
    }

    .modal-battles-select button.button-create svg {
        margin-right: 7px;
        fill: #ffffff;
    }

    @media only screen and (max-width: 1190px) {
        .modal-battles-select {
            width: calc(100vw - 20px);
        }

        .modal-battles-select .content-loading,
        .modal-battles-select .content-list {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 1000px) {
        .modal-battles-select .header-title,
        .modal-battles-select .select-header {
            width: 100%;
        }

        .modal-battles-select .header-filters {
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width: 800px) {
        .modal-battles-select .content-loading,
        .modal-battles-select .content-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media only screen and (max-width: 600px) {
        .modal-battles-select .content-loading,
        .modal-battles-select .content-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .modal-battles-select .header-filters > * {
            max-width: 100%;
        }
    }
</style>
