<template>
    <div :class="$style['battles']">
        <div
            :class="[
                $style['battles-header'],
                { [$style['no-border']]: battlesGetRouteName === 'BattlesGame' }
            ]"
        >
            <BattlesHeaderOverview v-if="battlesGetRouteName === 'BattlesOverview'" />
            <BattlesHeaderCreate v-else-if="battlesGetRouteName === 'BattlesCreate'" />
            <BattlesHeaderGame v-else-if="battlesGetRouteName === 'BattlesGame'" />
        </div>
        <div :class="$style['battles-content']">
            <transition name="slide-fade" mode="out-in">
                <router-view v-bind:key="$route.fullPath" />
            </transition>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import BattlesHeaderOverview from '@/components/battles/BattlesHeaderOverview';
    import BattlesHeaderCreate from '@/components/battles/BattlesHeaderCreate';
    import BattlesHeaderGame from '@/components/battles/BattlesHeaderGame';
    export default {
        name: 'Battles',
        metaInfo: {
            title: 'Battles - Rollbet.gg'
        },
        components: {
            BattlesHeaderOverview,
            BattlesHeaderCreate,
            BattlesHeaderGame
        },
        methods: {
            ...mapActions(['socketConnectBattles', 'socketDisconnectBattles'])
        },
        computed: {
            battlesGetRouteName() {
                return this.$route.name;
            }
        },
        created() {
            this.socketConnectBattles();
        },
        beforeRouteLeave(to, from, next) {
            this.socketDisconnectBattles();
            next();
        }
    };
</script>
<style module>
    .battles {
        max-width: 1345px;
        width: 100%;
        margin: 0 auto;
        padding: 45px 10px;
    }

    @media only screen and (max-width: 1500px) {
        .battles {
            padding: 30px 10px 45px 10px;
        }
    }
</style>
