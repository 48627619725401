<template>
    <div :class="$style['battles-create-filters']">
        <div :class="$style['filters-group']">
            <p :class="$style['group-title']">Choose A Mode</p>
            <div :class="$style['group-list']">
                <button
                    v-for="(item, index) in ['1v1', '1v1v1', '1v1v1v1', '2v2']"
                    v-on:click="battlesModeButton(item)"
                    v-bind:key="item"
                    :class="[
                        $style['list-item'],
                        { [$style['active']]: battlesFilterMode === item }
                    ]"
                    class="octagonal before after hoverable"
                >
                    <div :class="$style['item-icon']">
                        <svg
                            width="9"
                            height="11"
                            viewBox="0 0 9 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.49883 0.635742C3.01005 0.635742 1.79883 1.79659 1.79883 3.22346C1.79883 4.65032 3.01005 5.81117 4.49883 5.81117C5.98761 5.81117 7.19883 4.65032 7.19883 3.22346C7.19883 1.79659 5.98761 0.635742 4.49883 0.635742Z"
                                fill="currentColor"
                            />
                            <path
                                d="M7.85932 7.49929C7.12012 6.77994 6.14018 6.38379 5.1 6.38379H3.9C2.85984 6.38379 1.87988 6.77994 1.14068 7.49929C0.4051 8.21511 0 9.15999 0 10.1599C0 10.3187 0.13432 10.4475 0.3 10.4475H8.7C8.86568 10.4475 9 10.3187 9 10.1599C9 9.15999 8.5949 8.21511 7.85932 7.49929Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <p>{{ item }}</p>
                    <div></div>
                </button>
            </div>
        </div>

        <div :class="$style['filters-group']">
            <p :class="$style['group-title']">Choose Version</p>
            <div :class="$style['group-list']">
                <button
                    v-on:click="battlesTypeButton('standard')"
                    v-bind:class="[
                        $style['list-item'],
                        { [$style['active']]: battlesFilterType === 'standard' }
                    ]"
                    class="octagonal before after hoverable"
                >
                    <div :class="$style['item-icon']">
                        <svg
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.862 6.649C4.81867 6.753 4.784 6.82233 4.758 6.857C4.74067 6.89167 4.706 6.92633 4.654 6.961C4.602 6.987 4.52833 7 4.433 7H2.834C2.73867 7 2.665 6.987 2.613 6.961C2.561 6.935 2.522 6.90033 2.496 6.857C2.47 6.81367 2.43967 6.74433 2.405 6.649L0.143 0.89C0.091 0.768666 0.0996667 0.673333 0.169 0.604C0.247 0.534667 0.359667 0.5 0.507 0.5H2.041C2.17967 0.5 2.27933 0.530333 2.34 0.591C2.40067 0.651666 2.457 0.751333 2.509 0.89L3.393 3.256C3.497 3.55067 3.601 4.01433 3.705 4.647C3.835 4.023 3.96067 3.55933 4.082 3.256L5.057 0.89C5.109 0.751333 5.16533 0.651666 5.226 0.591C5.29533 0.530333 5.39933 0.5 5.538 0.5H6.903C7.05033 0.5 7.15867 0.534667 7.228 0.604C7.29733 0.673333 7.306 0.768666 7.254 0.89L4.862 6.649ZM11.6439 2.437V2.268C11.6439 1.982 11.3406 1.839 10.7339 1.839C10.1359 1.839 9.83694 1.982 9.83694 2.268V2.385C9.83694 2.55833 9.94094 2.671 10.1489 2.723L12.2159 3.23C13.2819 3.49 13.8149 3.97533 13.8149 4.686V5.297C13.8149 5.817 13.5593 6.25467 13.0479 6.61C12.5366 6.95667 11.7479 7.13 10.6819 7.13C9.61594 7.13 8.8316 6.95667 8.32894 6.61C7.82627 6.25467 7.57494 5.82133 7.57494 5.31V5.05C7.57494 4.82467 7.69194 4.712 7.92594 4.712H9.30394C9.53794 4.712 9.65494 4.82467 9.65494 5.05V5.206C9.65494 5.50067 9.99727 5.648 10.6819 5.648C11.3839 5.648 11.7349 5.50933 11.7349 5.232V5.102C11.7349 4.92867 11.5616 4.79867 11.2149 4.712L9.21294 4.179C8.82294 4.075 8.48494 3.88867 8.19894 3.62C7.9216 3.34267 7.78294 3.03067 7.78294 2.684V2.268C7.78294 1.73933 8.01694 1.293 8.48494 0.929C8.95294 0.556333 9.70694 0.369999 10.7469 0.369999C11.7956 0.369999 12.5539 0.551999 13.0219 0.916C13.4899 1.28 13.7239 1.73067 13.7239 2.268V2.437C13.7239 2.567 13.6936 2.658 13.6329 2.71C13.5809 2.762 13.4899 2.788 13.3599 2.788H11.9819C11.7566 2.788 11.6439 2.671 11.6439 2.437Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <p>STANDARD</p>
                    <div></div>
                </button>
                <button
                    v-on:click="battlesTypeButton('group')"
                    v-bind:class="[
                        $style['list-item'],
                        { [$style['active']]: battlesFilterType === 'group' }
                    ]"
                    class="octagonal before after hoverable"
                >
                    <div :class="$style['item-icon']">
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.889 8.22836L11.6574 7.07361L9.10955 9.46223C8.65474 9.88861 8.0839 10.1666 7.46387 10.2706L9.08723 11.7926C9.23589 11.932 9.47708 11.932 9.62574 11.7926L12.889 8.7333C13.0378 8.59384 13.0378 8.36773 12.889 8.22836Z"
                                fill="currentColor"
                            />
                            <path
                                d="M11.1549 6.53446C11.5842 6.13206 11.5843 5.47724 11.1555 5.07456C11.155 5.0741 11.1545 5.07363 11.154 5.07317C11.0479 4.97378 10.9239 4.89717 10.7889 4.84594C10.906 4.56841 10.9306 4.09907 10.543 3.73554C10.542 3.73461 10.541 3.73359 10.5399 3.73266C10.4343 3.63429 10.3111 3.55842 10.177 3.50747C10.2941 3.23013 10.3188 2.7607 9.93138 2.39735C9.93108 2.39707 9.93088 2.39688 9.93069 2.3967C9.82447 2.29712 9.70041 2.22033 9.56523 2.1691C9.68226 1.89157 9.70695 1.42214 9.31928 1.0586C9.31918 1.0586 9.31918 1.05851 9.31909 1.05851C9.31899 1.05842 9.31889 1.05832 9.31879 1.05823C8.88927 0.655557 8.19049 0.655557 7.76098 1.05823L7.0389 1.73509L7.40812 2.08123C8.02339 2.65805 8.02339 3.59654 7.40822 4.17336C7.20114 4.3674 6.94676 4.50072 6.66948 4.56422C6.60313 4.81767 6.46468 5.05792 6.25374 5.25558C6.04697 5.44944 5.79298 5.58267 5.51599 5.64627C5.44826 5.90585 5.30604 6.14405 5.09937 6.33781C4.89259 6.53167 4.63861 6.6649 4.36162 6.72849C4.29388 6.98808 4.15167 7.22628 3.94499 7.42013C3.64698 7.69943 3.25068 7.8534 2.8292 7.8534C2.40771 7.8534 2.01141 7.69952 1.7134 7.42004L1.34418 7.0739L0.112546 8.22864C-0.0362134 8.36811 -0.0362134 8.59413 0.112546 8.73359L3.37574 11.7928C3.5245 11.9323 3.76559 11.9322 3.91435 11.7928L6.25027 9.603H6.90838C7.53615 9.603 8.12633 9.37372 8.57023 8.95766L11.1549 6.53446Z"
                                fill="currentColor"
                            />
                            <path
                                d="M2.25226 6.91484C2.571 7.21376 3.08789 7.21376 3.40664 6.91484C3.72548 6.61602 3.72548 6.13153 3.40664 5.83261C3.72548 6.13153 4.24227 6.13153 4.56111 5.83261C4.87985 5.53379 4.87985 5.04921 4.56111 4.75039C4.87985 5.04921 5.39674 5.04921 5.71549 4.75039C6.03423 4.45156 6.03423 3.96698 5.71549 3.66816C6.03423 3.96698 6.55112 3.96698 6.86986 3.66816C7.1887 3.36924 7.1887 2.88475 6.86986 2.58584L5.24223 1.05993C4.92349 0.76111 4.4066 0.76111 4.08786 1.05993C3.76911 1.35875 3.76911 1.84334 4.08786 2.14216L4.29176 2.33341C3.97301 2.03449 3.45612 2.03449 3.13738 2.33341C2.81864 2.63223 2.81864 3.11682 3.13738 3.41564L3.54528 3.79804C3.22654 3.49922 2.70965 3.49922 2.39091 3.79804C2.07206 4.09687 2.07206 4.58145 2.39091 4.88027L2.7988 5.26277C2.47996 4.96386 1.96317 4.96386 1.64433 5.26277C1.32559 5.56159 1.32559 6.04608 1.64433 6.345L2.25226 6.91484Z"
                                fill="currentColor"
                            />
                            <path
                                d="M7.22218 0.552491C7.27851 0.499681 7.33811 0.451148 7.40009 0.406055L7.17735 0.197233C7.06152 0.0885456 6.88429 0.0616758 6.73781 0.130291L5.79785 0.5709L6.5002 1.22944L7.22218 0.552491Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <p>GROUP</p>
                    <div></div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BattlesCreateFilters',
        data() {
            return {
                battlesLevel: 0,
                battlesFunding: 0
            };
        },
        methods: {
            ...mapActions(['battlesSetFilterType', 'battlesSetFilterMode']),
            battlesTypeButton(value) {
                if (value === 'standard' && this.battlesFilterMode === '2v2') {
                    this.battlesSetFilterMode('1v1');
                }
                this.battlesSetFilterType(value);
            },
            battlesModeButton(value) {
                if (value === '2v2' && this.battlesFilterType === 'standard') {
                    this.battlesSetFilterType('group');
                }
                this.battlesSetFilterMode(value);
            }
        },
        computed: {
            ...mapGetters(['battlesFilterMode', 'battlesFilterType'])
        },
        created() {
            this.battlesLevel = this.battlesFilterLevel;
            this.battlesFunding = this.battlesFilterFunding;
        }
    };
</script>

<style module>
    .battles-create-filters {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px 7px;
    }

    .battles-create-filters .filters-group:first-child {
        width: calc(66.66% - 3.5px);
    }

    .battles-create-filters .filters-group:last-child {
        width: calc(33.33% - 3.5px);
    }

    .battles-create-filters .group-title {
        display: flex;
        align-items: center;
        color: #537088;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        gap: 14px;
    }

    .battles-create-filters .group-title::after {
        background: linear-gradient(90deg, #092b45, #07273f 75%, transparent);
        height: 1px;
        content: '';
        flex: 1;
    }

    .battles-create-filters .group-list {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    .battles-create-filters .list-item {
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        filter: drop-shadow(0 2px 0 #0a2134);
        color: #537088;
        font-size: 15px;
        font-weight: 600;
        gap: 7px;
        flex: 1;
    }

    .battles-create-filters .list-item::before,
    .battles-create-filters .list-item::after {
        background: #0e263c;
        font-size: 5px;
    }

    .battles-create-filters .list-item.active::before {
        background: #183957;
    }

    .battles-create-filters .list-item.active::after {
        background: #071929;
    }

    .battles-create-filters .list-item.active {
        color: white;
    }

    .battles-create-filters .list-item.active .item-icon {
        color: #21b894;
    }

    .battles-create-filters .item-icon {
        min-width: 25px;
        width: 25px;
        height: 25px;
        font-size: 2px;
        background: #193652;
        clip-path: var(--octagonal-main);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 850px) {
        .battles-create-filters .filters-group:first-child .group-list {
            flex-wrap: wrap;
        }

        .battles-create-filters .filters-group:first-child .list-item {
            flex: unset;
            width: calc(50% - 3.5px);
        }

        .battles-create-filters .filters-group:last-child .group-list {
            flex-direction: column;
        }

        .battles-create-filters .filters-group:last-child .list-item {
            flex: unset;
            width: 100%;
        }
    }

    @media screen and (max-width: 435px) {
        .battles-create-filters {
            flex-direction: column;
        }

        .battles-create-filters .filters-group {
            width: 100% !important;
        }

        .battles-create-filters .filters-group:last-child .group-list {
            flex-direction: row;
        }

        .battles-create-filters .filters-group:last-child .list-item {
            flex: 1;
            width: unset;
        }
    }
</style>
