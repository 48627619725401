var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['battles-create']},[_c('BattlesCreateFilters'),_c('div',{class:_vm.$style['create-select']},[_c('div',{class:_vm.$style['select-wrap']},[_c('div',{class:_vm.$style['select-list'],style:({ minWidth: (_vm.battlesGetBoxes.length + 1) * (180 + 7) + 'px' }),on:{"dragover":function($event){$event.preventDefault();}}},[_c('button',{class:_vm.$style['button-add'],on:{"click":function($event){return _vm.modalsSetShow('BattlesSelect')}}},[_c('div',{class:_vm.$style['button-inner']},[_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.5858 4.42169H12V7.6506H7.5858V12H4.4142V7.6506H0V4.42169H4.4142V0H7.5858V4.42169Z"}})])])]),_vm._l((_vm.battlesGetBoxes),function(box,index){return _c('div',{key:index,class:[
                        _vm.$style['select-item'],
                        { [_vm.$style['active']]: _vm.draggedIndex === box.pos }
                    ],style:({
                        transform: `translateX(${(box.pos + 1) * (180 + 7)}px)`,
                        transition: 'transform 0.3s ease'
                    }),attrs:{"draggable":true},on:{"dragstart":function($event){return _vm.onDragStart($event, box.pos)},"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter($event, box.pos)},"dragover":function($event){$event.preventDefault();},"dragend":_vm.onDragEnd}},[_c('svg',{attrs:{"stroke":"currentColor","fill":"none","stroke-width":"2","viewBox":"0 0 24 24","aria-hidden":"true","height":"1em","width":"1em","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"}})]),_c('BattlesBoxElement',{attrs:{"box":box}})],1)})],2)])]),_c('hr'),_c('BattlesCreateFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }