<template>
    <div :class="$style['battles-header-overview']">
        <div :class="$style['overview-control']">
            <button
                v-on:click="battlesSetFilterSortGames('price')"
                :class="[
                    {
                        [$style['active']]: battlesFilterSortGames === 'price'
                    },
                    $style['control-sort']
                ]"
                class="octagonal before after hoverable"
            >
                Price
            </button>
            <button
                v-on:click="battlesSetFilterSortGames('date')"
                :class="[
                    {
                        [$style['active']]: battlesFilterSortGames === 'date'
                    },
                    $style['control-sort']
                ]"
                class="octagonal before after hoverable"
            >
                Date
            </button>
            <router-link
                to="/battles/create"
                :class="$style['control-create']"
                class="octagonal before after hoverable"
            >
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.37021 7.85824L5.96166 10.5285L4.92552 11.5963L5.96313 12.6649L4.92625 13.7327L3.11136 11.8636L1.03687 14L0 12.9322L2.07448 10.7951L0.259585 8.9268L1.29646 7.859L2.33333 8.92605L3.37021 7.85824ZM0.400377 0L3.00063 0.00226551L11.6659 8.9268L12.7035 7.859L13.7404 8.9268L11.9263 10.7958L14 12.9322L12.9631 14L10.8886 11.8636L9.07375 13.7327L8.03687 12.6649L9.07375 11.5963L0.402577 2.66649L0.400377 0ZM11.0016 0L13.5996 0.00226551L13.6011 2.66271L10.6291 5.72264L8.03614 3.05313L11.0016 0Z"
                        fill="white"
                    />
                </svg>
                CREATE
            </router-link>
        </div>
        <div :class="$style['overview-title']">
            <hr />
            <div :class="$style['title-content']">
                <div :class="$style['content-logo']" class="octagonal before after">
                    <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.09239 8.98085L7.23916 12.0326L5.98099 13.2529L7.24094 14.4741L5.98188 15.6945L3.77807 13.5584L1.25906 16L0 14.7797L2.51901 12.3372L0.315211 10.2021L1.57427 8.98171L2.83333 10.2012L4.09239 8.98085ZM0.486172 0L3.64362 0.00258915L14.1658 10.2021L15.4257 8.98171L16.6848 10.2021L14.4819 12.3381L17 14.7797L15.7409 16L13.2219 13.5584L11.0181 15.6945L9.75906 14.4741L11.0181 13.2529L0.488844 3.04741L0.486172 0ZM13.3591 0L16.5138 0.00258915L16.5156 3.0431L12.9067 6.54016L9.75817 3.48929L13.3591 0Z"
                            fill="#21B894"
                        />
                    </svg>
                </div>
                <p>Battles Lobby</p>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import BattlesFilterSort from '@/components/battles/BattlesFilterSort';

    export default {
        name: 'BattlesHeaderOverview',
        components: {
            BattlesFilterSort
        },
        methods: {
            ...mapActions(['battlesSetFilterSortGames']),
            battlesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            ...mapGetters(['battlesGames', 'userBalanceData', 'battlesFilterSortGames']),
            battlesGetAmountTotal() {
                let amount = 0;

                for (const game of this.battlesGames) {
                    amount = Math.floor(amount + game.amount);
                }

                return amount;
            }
        }
    };
</script>

<style module>
    .battles-header-overview {
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: column;
        margin-bottom: 7px;
    }

    .battles-header-overview .overview-control {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    .battles-header-overview .control-sort {
        height: 36px;
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 600;
        color: #537088;
        filter: drop-shadow(0 2px 0 #0a2134);
    }

    .battles-header-overview .control-sort::before,
    .battles-header-overview .control-sort::after {
        font-size: 3px;
        background: #132c41;
    }

    .battles-header-overview .control-sort.active {
        filter: unset;
        color: #21b894;
    }

    .battles-header-overview .control-sort.active::before,
    .battles-header-overview .control-sort.active::after {
        background: #064552;
    }

    .battles-header-overview .control-create {
        height: 39px;
        width: 113px;
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: center;
        gap: 7px;
        font-size: 15px;
        font-weight: 600;
        color: white;
        filter: drop-shadow(0 2px 0 #136762), drop-shadow(0 2px 15px #21b89459);
    }

    .battles-header-overview .control-create::before,
    .battles-header-overview .control-create::after {
        background: #21b894;
        font-size: 3px;
    }

    .battles-header-overview .overview-title {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .battles-header-overview .overview-title hr {
        flex: 1;
        border-color: #184060;
    }

    .battles-header-overview .overview-title hr:first-child {
        mask-image: linear-gradient(90deg, transparent, black);
    }

    .battles-header-overview .overview-title hr:last-child {
        mask-image: linear-gradient(-90deg, transparent, black);
    }

    .battles-header-overview .title-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }

    .battles-header-overview .title-content p {
        color: white;
        font-weight: 600;
        font-size: 18px;
    }

    .battles-header-overview .content-logo {
        position: absolute;
        top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }

    .battles-header-overview .content-logo::before {
        font-size: 2px;
        background: linear-gradient(-45deg, #18cc87, transparent, transparent, #18cc87);
    }

    .battles-header-overview .content-logo::after {
        font-size: 2px;
        background: radial-gradient(circle at center, #18cc8759, transparent);
    }
</style>
