<template>
    <div
        ref="battlesGameBoxes"
        v-bind:class="[
            $style['battles-game-boxes'],
            { [$style['boxes-rolling']]: game !== null && game.state === 'rolling' }
        ]"
    >
        <hr />
        <hr />
        <div v-if="battlesGetMore" :class="$style['boxes-more']">+{{ battlesGetMore }} more</div>
        <div :class="$style['boxes-split']">
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
        </div>
        <transition-group :class="$style['boxes-list']" name="list" tag="div">
            <button
                v-for="(box, index) in battlesGetBoxes.slice(
                    game.state !== 'completed' ? this.battlesGetRound - 1 : 0,
                    this.battlesGetRound + 11
                )"
                v-bind:key="box.pos"
                v-on:click="battlesBoxButton(box.box)"
                v-bind:class="[
                    $style['button-box'],
                    {
                        [$style['button-active']]:
                            ['created', 'countdown', 'pending'].includes(game.state) ||
                            (box.pos === battlesGetRound - 1 && game.state !== 'completed')
                    }
                ]"
                class="hoverable"
            >
                <img v-bind:src="unboxImagePath + '/public/img/' + box.box.slug + '.png'" />
            </button>
        </transition-group>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconCheckGradient from '@/components/icons/IconCheckGradient';

    export default {
        name: 'BattlesGameBoxes',
        components: {
            IconCheckGradient
        },
        props: ['game'],
        data() {
            return {
                unboxImagePath: process.env.VUE_APP_BACKEND_URL,
                containerWidth: 0
            };
        },
        methods: {
            ...mapActions(['modalsSetShow', 'modalsSetData']),
            battlesBoxButton(box) {
                this.modalsSetData({ box: box });
                this.modalsSetShow('Box');
            },
            handleBattlesBoxContainer() {
                if (this.$refs.battlesGameBoxes) {
                    this.containerWidth = this.$refs.battlesGameBoxes.offsetWidth;
                }
            }
        },
        computed: {
            battlesGetRound() {
                let round = 1;

                if (
                    this.game?._id !== undefined &&
                    this.game.bets[0].outcomes !== undefined &&
                    this.game.bets[0].outcomes.length >= 1
                ) {
                    round = this.game.bets[0].outcomes.length;
                }

                return round;
            },
            battlesGetBoxes() {
                let pos = 0;
                let boxes = [];

                if (this.game?._id !== undefined) {
                    for (const box of this.game.boxes) {
                        for (let i = 0; i < box.count; i++) {
                            boxes.push({ pos: pos, box: box.box });
                            pos = pos + 1;
                        }
                    }
                }

                return boxes;
            },
            battlesGetMore() {
                const containerItemFits = Math.round(this.containerWidth / 85);
                return Math.max(0, this.battlesGetBoxes.length - containerItemFits);
            }
        },
        mounted() {
            // Calculate items on initial load
            this.handleBattlesBoxContainer();
            // Add resize event listener
            window.addEventListener('resize', this.handleBattlesBoxContainer);
        },
        beforeDestroy() {
            // Clean up the event listener when the component is destroyed
            window.removeEventListener('resize', this.handleBattlesBoxContainer);
        }
    };
</script>

<style scoped>
    .list-move,
    .list-leave-active {
        transition: all 0.3s;
    }

    .list-leave-active {
        position: absolute;
    }

    .list-leave-to {
        transform: translateX(-85px);
    }
</style>

<style module>
    .battles-game-boxes {
        max-width: calc(85px * 9);
        width: 100%;
        height: 87px;
        position: relative;
        background: radial-gradient(circle at center, #18477179, transparent 80%);
        background-size: 85px 85px;
        background-repeat: repeat-x;
        background-position: 0px 1px;
        display: flex;
        align-items: center;
        z-index: 1;
    }

    .battles-game-boxes hr {
        position: absolute;
        top: 0;
        height: 100%;
        border: unset;
        width: 1px;
        background: #204a6f;
        mask-image: linear-gradient(0deg, transparent, black, transparent);
    }

    .battles-game-boxes > hr:nth-child(1) {
        left: -1px;
    }

    .battles-game-boxes > hr:nth-child(2) {
        right: -1px;
    }

    .boxes-split {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .boxes-split hr:nth-child(1) {
        left: 84px;
    }

    .boxes-split hr:nth-child(2) {
        left: calc((85px * 2) - 1px);
    }

    .boxes-split hr:nth-child(3) {
        left: calc((85px * 3) - 1px);
    }

    .boxes-split hr:nth-child(4) {
        left: calc((85px * 4) - 1px);
    }

    .boxes-split hr:nth-child(5) {
        left: calc((85px * 5) - 1px);
    }

    .boxes-split hr:nth-child(6) {
        left: calc((85px * 6) - 1px);
    }

    .boxes-split hr:nth-child(7) {
        left: calc((85px * 7) - 1px);
    }

    .boxes-split hr:nth-child(8) {
        left: calc((85px * 8) - 1px);
    }

    .battles-game-boxes .boxes-more {
        position: absolute;
        font-size: 13px;
        font-weight: 600;
        color: white;
        padding: 0px 4px;
        width: 87px;
        height: 19px;
        background: linear-gradient(90deg, transparent, #204a6f, transparent);
        text-align: center;
        white-space: nowrap;
        left: 100%;
        transform: rotate(-90deg) translate(0%, calc(-43.5px - 9.5px));
        z-index: 1;
    }

    .battles-game-boxes .boxes-list {
        height: 100%;
        width: 100%;
        position: absolute;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .battles-game-boxes button.button-box {
        width: 85px;
        min-width: 85px;
        height: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
    }

    .battles-game-element .battles-game-boxes button.button-box {
        /* pointer-events: none; */
    }

    .battles-game-boxes button.button-box img {
        width: 60px;
        opacity: 0.3;
    }

    .battles-game-boxes button.button-active img {
        transform: scale(1) !important;
    }

    .battles-game-boxes button.button-active img {
        opacity: 1 !important;
        mix-blend-mode: normal !important;
    }
</style>
