var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['battles-header-overview']},[_c('div',{class:_vm.$style['overview-control']},[_c('button',{staticClass:"octagonal before after hoverable",class:[
                {
                    [_vm.$style['active']]: _vm.battlesFilterSortGames === 'price'
                },
                _vm.$style['control-sort']
            ],on:{"click":function($event){return _vm.battlesSetFilterSortGames('price')}}},[_vm._v(" Price ")]),_c('button',{staticClass:"octagonal before after hoverable",class:[
                {
                    [_vm.$style['active']]: _vm.battlesFilterSortGames === 'date'
                },
                _vm.$style['control-sort']
            ],on:{"click":function($event){return _vm.battlesSetFilterSortGames('date')}}},[_vm._v(" Date ")]),_c('router-link',{staticClass:"octagonal before after hoverable",class:_vm.$style['control-create'],attrs:{"to":"/battles/create"}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.37021 7.85824L5.96166 10.5285L4.92552 11.5963L5.96313 12.6649L4.92625 13.7327L3.11136 11.8636L1.03687 14L0 12.9322L2.07448 10.7951L0.259585 8.9268L1.29646 7.859L2.33333 8.92605L3.37021 7.85824ZM0.400377 0L3.00063 0.00226551L11.6659 8.9268L12.7035 7.859L13.7404 8.9268L11.9263 10.7958L14 12.9322L12.9631 14L10.8886 11.8636L9.07375 13.7327L8.03687 12.6649L9.07375 11.5963L0.402577 2.66649L0.400377 0ZM11.0016 0L13.5996 0.00226551L13.6011 2.66271L10.6291 5.72264L8.03614 3.05313L11.0016 0Z","fill":"white"}})]),_vm._v(" CREATE ")])],1),_c('div',{class:_vm.$style['overview-title']},[_c('hr'),_c('div',{class:_vm.$style['title-content']},[_c('div',{staticClass:"octagonal before after",class:_vm.$style['content-logo']},[_c('svg',{attrs:{"width":"17","height":"16","viewBox":"0 0 17 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.09239 8.98085L7.23916 12.0326L5.98099 13.2529L7.24094 14.4741L5.98188 15.6945L3.77807 13.5584L1.25906 16L0 14.7797L2.51901 12.3372L0.315211 10.2021L1.57427 8.98171L2.83333 10.2012L4.09239 8.98085ZM0.486172 0L3.64362 0.00258915L14.1658 10.2021L15.4257 8.98171L16.6848 10.2021L14.4819 12.3381L17 14.7797L15.7409 16L13.2219 13.5584L11.0181 15.6945L9.75906 14.4741L11.0181 13.2529L0.488844 3.04741L0.486172 0ZM13.3591 0L16.5138 0.00258915L16.5156 3.0431L12.9067 6.54016L9.75817 3.48929L13.3591 0Z","fill":"#21B894"}})])]),_c('p',[_vm._v("Battles Lobby")])]),_c('hr')])])
}
var staticRenderFns = []

export { render, staticRenderFns }