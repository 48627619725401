var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['battles-overview']},[(_vm.myBattles.length || _vm.allBattles.length)?_c('div',{key:"data",class:_vm.$style['content-list']},[(_vm.myBattles.length)?_c('p',{class:_vm.$style['list-title']},[_vm._v("My Battles")]):_vm._e(),_vm._l((_vm.myBattles),function(game,index){return _c('div',{key:game._id || index,class:[
                _vm.$style['list-item'],
                { [_vm.$style['pop']]: index === 0 && _vm.mounting === 'my' }
            ]},[_c('BattlesGameElement',{attrs:{"game":game}})],1)}),(_vm.allBattles.length)?_c('p',{class:_vm.$style['list-title']},[_vm._v("All Battles")]):_vm._e(),_vm._l((_vm.allBattles),function(game,index){return _c('div',{key:game._id || index,class:[
                _vm.$style['list-item'],
                { [_vm.$style['pop']]: index === 0 && _vm.mounting === 'all' }
            ]},[_c('BattlesGameElement',{attrs:{"game":game}})],1)})],2):_c('div',{key:"empty",class:_vm.$style['content-empty']},[_vm._v("There are no battles.")])])
}
var staticRenderFns = []

export { render, staticRenderFns }